<template>
  <div id="ChainExplorerDemo">
    <h1>Tulip's  Explorer Demo</h1>
    <p>1 - dsfiodjio</p>
    <p>2 - dfsdsf</p>
    <p>3 - fgijowfe</p>
  </div>

</template>

<script>

export default {
  name: 'ChainExplorerDemo',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>
<style scoped>
#ChainExplorerDemo{
  color: #fff;
  margin: 2rem auto;
  width: 100%;
  min-height: 100vh;
}
.chain{
  color: #fff;
  font-size: 15px;
  transition: 1s;
  font-weight: bold;
  cursor:pointer;
}

</style>
